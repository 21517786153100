html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  font-size: 12px;
}

@media (max-width: 1200px) {
  html,
  body {
    width: 1200px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* adminaccount.module.scss から移してきたもの */
div,
ul,
li,
p {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}

/* スクロールバーの設定(!importantを使用しないと上書きされない） */
::-webkit-scrollbar,
*::-webkit-scrollbar {
  height: 15px !important;
  width: 15px !important;
  background-color: unset !important;
}

::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  box-shadow: none !important;
}

::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  border-radius: 15px !important;
  background: #d1d1d1 !important;
  box-shadow: none !important;
}
